<template>
  <article-wrapper heading="Consultation and Design">
    <h2>
      Dreams become reality when you start with a master plan
      <br>
      <br>
      call me today to set up your consultation.
    </h2>

    <h3>Consultation</h3>
    <p>
      At the initial consultation it is important that all interested parties are
      present and all ideas are discussed. The consultation is an on-site
      brainstorming session. We consider all options and discuss objectives
      and concerns. We will address Plantings, Decks Patios, Swimming
      Pools, Walkways, Grading, Retaining Walls, Drainage, Lighting,
      Irrigation, Gardening, Fences, Deer Control etc.
    </p>

    <h3>Survey</h3>
    <p>
      It is important to start your project with a Base Map that is to scale. You
      may already have a survey in your real estate closing package. If not, I
      can prepare a base map to scale using a laser rangefinder.
    </p>
    <img
      loading="lazy"
      alt="Dan surveying with his pad and equipment by the edge of a
          lake to get an accurate map of the property."
      :src="require('@/assets/photos/original/dan-survey-pad-lake.jpg')"
    >

    <h3>Design</h3>
    <p>
      The sketches I produce on-site may be all that is needed for clients
      looking to do the work themselves. Clients who will hire a contractor to
      do the work may need more detail. This drawing will be prepared in the
      office. All site and landscape recommendations will be hyperlinked on a
      separate word document. This information can be emailed to you and
      the contractors.
    </p>
    <img
      loading="lazy"
      style="padding-bottom: 24px;"
      alt="Dan Chitwood working on a design on his drafting desk."
      :src="require('@/assets/photos/original/dan-at-desk.jpg')"
    >
  </article-wrapper>
</template>
